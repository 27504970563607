body {
  padding: 1rem;
}

#ver {
  position: fixed;
  bottom: 1em;
  right: 1em;
}

.pick-page {
  .slot-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    button {
      border: none;
      padding: 0.5rem;
      border-radius: 0.3em;
      font-weight: bold;
    }
  }
}

.overview-page {
  .slot-overview {
    display: flex;
    flex-direction: column;
    .slot-box {
      background-color: rgb(235, 235, 235);
      margin-bottom: 1rem;
      padding: 1rem;
      border-radius: 0.3em;
    }
    hr {
      width: 100%;
    }
  }
  .overview-user-row {
    display: flex;
    justify-content: space-between;
    padding: 0.4rem;
    align-items: center;
  }
}

.schedule-page {
  .sch-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0.5em;
  }
}

.updateSlots {
  position: fixed;
  top: 1rem;
  right: 1rem;
}

.nav-bar {
  button {
    border: none;
    background: none;
  }
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  form {
    .form-group {
      display: flex;
      flex-direction: column;
    }
  }
}

.slot-summary {
  .summary-row {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px rgb(197, 197, 197);
    padding: 0.25rem;
  }
}

.week-of {
  width: 100%;
  text-align: center;
  .highlight {
    padding: 0.4rem;
    border-radius: 0.25rem;
    background-color: rgb(197, 197, 197);
  }
}

.accounts-page {
  .create-user {
    background-color: rgb(197, 197, 197);
    border-radius: 0.3em;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    .form-input {
      input {
        border: none;
        border-radius: 0.3em;
        width: 100%;
      }
    }
    button {
      border: none;
      margin-top: 1rem;
      border-radius: 0.3em;
      font-weight: bold;
      padding: 0.25rem;
    }
  }
  .user-list {
    .user-row {
      background-color: rgb(224, 224, 224);
      border-radius: 0.3em;
      margin-top: 1rem;
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .user-row-name {
        font-weight: bold;
      }
    }
  }
}

.settings-page {
  .slot-list {
    .slot-row {
      display: flex;
      justify-content: space-between;
      padding: 0.4rem;
      #maxSlotSize {
        width: 16%;
        text-align: center;
        border: none;
        background-color: rgb(224, 224, 224);
        border-radius: 0.3em;
      }
    }
  }
}
