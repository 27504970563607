body {
  padding: 1rem;
}

#ver {
  position: fixed;
  bottom: 1em;
  right: 1em;
}

.pick-page .slot-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
}

.pick-page .slot-grid button {
  border: none;
  padding: 0.5rem;
  border-radius: 0.3em;
  font-weight: bold;
}

.overview-page .slot-overview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.overview-page .slot-overview .slot-box {
  background-color: #ebebeb;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.3em;
}

.overview-page .slot-overview hr {
  width: 100%;
}

.overview-page .overview-user-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0.4rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.schedule-page .sch-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 0.5em;
}

.updateSlots {
  position: fixed;
  top: 1rem;
  right: 1rem;
}

.nav-bar button {
  border: none;
  background: none;
}

.login {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 80vh;
}

.login form .form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.slot-summary .summary-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: solid 1px #c5c5c5;
  padding: 0.25rem;
}

.week-of {
  width: 100%;
  text-align: center;
}

.week-of .highlight {
  padding: 0.4rem;
  border-radius: 0.25rem;
  background-color: #c5c5c5;
}

.accounts-page .create-user {
  background-color: #c5c5c5;
  border-radius: 0.3em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 1rem;
}

.accounts-page .create-user .form-input input {
  border: none;
  border-radius: 0.3em;
  width: 100%;
}

.accounts-page .create-user button {
  border: none;
  margin-top: 1rem;
  border-radius: 0.3em;
  font-weight: bold;
  padding: 0.25rem;
}

.accounts-page .user-list .user-row {
  background-color: #e0e0e0;
  border-radius: 0.3em;
  margin-top: 1rem;
  padding: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.accounts-page .user-list .user-row .user-row-name {
  font-weight: bold;
}

.settings-page .slot-list .slot-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0.4rem;
}

.settings-page .slot-list .slot-row #maxSlotSize {
  width: 16%;
  text-align: center;
  border: none;
  background-color: #e0e0e0;
  border-radius: 0.3em;
}
